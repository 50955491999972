import { getHeaders } from "utils/api/common";
import {
  ApiAdminUser,
  ApiAdminUserDirectDeposit,
  ApiAdminUserPinwheelStatus,
  ApiAdminUserPlaidStatus,
  ApiAdminUserPlaidTransactions,
  ApiAdminUsers,
} from "utils/api/admin/user";
import { ApiAdminWaitlist } from "utils/api/admin/waitlist";
import baseApiSlice from "store/api/apiSlice";
import { ApiAdminCardTransactions } from "utils/api/admin/cardTransaction";
import { ApiAdminIntegrationErrors } from "utils/api/admin/integrationError";
import decamelizeKeys from "decamelize-keys";
import { ApiAdminFinancialHealthSurveyResults } from "utils/api/admin/financialHealth";

const tagTypes = [
  "AdminUsers",
  "AdminUser",
  "AdminWaitlist",
  "AdminUserPinwheelStatus",
  "AdminUserDirectDeposit",
  "AdminUserPlaidTransactions",
  "AdminUserPlaidStatus",
  "AdminUserUnresolvedIntegrationErrors",
  "AdminCardTransaction",
  "AdminFinancialHealthSurveyResults",
];

baseApiSlice.enhanceEndpoints({ addTagTypes: tagTypes });

const apiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminUsers: builder.query<ApiAdminUsers, number>({
      query: (page = 1) => `/admin/users?page=${page}`,
      providesTags: ["AdminUsers"],
    }),
    getAdminUsersCardholders: builder.query<ApiAdminUsers, number>({
      query: (page = 1) => `/admin/card_accounts?page=${page}`,
      providesTags: ["AdminUsers"],
    }),
    getAdminUsersApplicants: builder.query<ApiAdminUsers, number>({
      query: (page = 1) => `/admin/card_applicants?page=${page}`,
      providesTags: ["AdminUsers"],
    }),
    getAdminUsersEligible: builder.query<ApiAdminUsers, number>({
      query: (page = 1) => `/admin/eligible_users?page=${page}`,
      providesTags: ["AdminUsers"],
    }),
    postAdminExportUserData: builder.mutation<void, void>({
      query: () => ({
        url: "/admin/export_user_data",
        method: "POST",
        headers: getHeaders(),
      }),
    }),
    postAdminExportWaitlistData: builder.mutation<void, void>({
      query: () => ({
        url: "/admin/export_waitlist_data",
        method: "POST",
        headers: getHeaders(),
      }),
    }),
    getAdminUser: builder.query<ApiAdminUser, string>({
      query: (id) => `/admin/users/${id}`,
      providesTags: ["AdminUser"],
    }),
    getAdminUserPinwheelStatus: builder.query<
      ApiAdminUserPinwheelStatus,
      string
    >({
      query: (id) => `/admin/users/${id}/pinwheel_status`,
      providesTags: ["AdminUserPinwheelStatus"],
    }),
    getAdminUserDirectDeposit: builder.query<ApiAdminUserDirectDeposit, string>(
      {
        query: (id) => `/admin/users/${id}/direct_deposit`,
        providesTags: ["AdminUserDirectDeposit"],
      }
    ),
    getAdminUserPlaidTransactions: builder.query<
      ApiAdminUserPlaidTransactions,
      string
    >({
      query: (id) => `/admin/users/${id}/plaid_transactions`,
      providesTags: ["AdminUserPlaidTransactions"],
    }),
    getAdminUserPlaidStatus: builder.query<ApiAdminUserPlaidStatus, string>({
      query: (id) => `/admin/users/${id}/plaid_status`,
      providesTags: ["AdminUserPlaidStatus"],
    }),
    getAdminWaitlist: builder.query<ApiAdminWaitlist, number>({
      query: (page = 1) => `/admin/waitlists?page=${page}`,
      providesTags: ["AdminWaitlist"],
    }),
    postAdminUserVerifyIdentity: builder.mutation<{ success: boolean }, string>(
      {
        query: (id) => ({
          url: `/admin/users/${id}/verify_identity`,
          method: "POST",
          headers: getHeaders(),
        }),
      }
    ),
    postAdminUserRefreshEligibility: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/users/${id}/refresh_eligibility`,
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["AdminUser"],
    }),
    postAdminUserDestroy: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/users/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
    }),
    postAdminUserDisconnect: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/users/${id}/disconnect`,
        method: "POST",
        headers: getHeaders(),
      }),
    }),
    postAdminUserImpersonate: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/users/${id}/impersonate`,
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["Dashboard", "UserProfile", "CardTransaction"],
    }),
    getAdminUserUnresolvedIntegrationErrors: builder.query<
      ApiAdminIntegrationErrors,
      string
    >({
      query: (id) => `/admin/users/${id}/unresolved_integration_errors`,
      providesTags: ["AdminUserUnresolvedIntegrationErrors"],
    }),
    postAdminIntegrationErrorResolve: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/integration_errors/${id}/resolve`,
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["AdminUserUnresolvedIntegrationErrors"],
    }),
    getAdminCardTransactions: builder.query<ApiAdminCardTransactions, number>({
      query: (page = 1) => `/admin/card_transactions?page=${page}`,
      providesTags: ["AdminCardTransaction"],
    }),
    postIssuePhysicalCard: builder.mutation<
      void,
      { id: string; expeditedFulfillment: boolean }
    >({
      query: ({ id, ...body }) => ({
        url: `/admin/users/${id}/issue_physical_card`,
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body),
      }),
    }),
    getAdminFinancialHealthSurveyResults: builder.query<
      ApiAdminFinancialHealthSurveyResults,
      number
    >({
      query: (page = 1) =>
        `/admin/financial_health/survey_results?page=${page}`,
      providesTags: ["AdminFinancialHealthSurveyResults"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAdminUsersQuery,
  useGetAdminUsersCardholdersQuery,
  useGetAdminUsersApplicantsQuery,
  useGetAdminUsersEligibleQuery,
  usePostAdminExportUserDataMutation,
  usePostAdminExportWaitlistDataMutation,
  useGetAdminUserQuery,
  useGetAdminUserPinwheelStatusQuery,
  useGetAdminUserDirectDepositQuery,
  useGetAdminUserPlaidTransactionsQuery,
  useGetAdminUserPlaidStatusQuery,
  useGetAdminWaitlistQuery,
  usePostAdminUserVerifyIdentityMutation,
  usePostAdminUserRefreshEligibilityMutation,
  usePostAdminUserDestroyMutation,
  usePostAdminUserDisconnectMutation,
  usePostAdminUserImpersonateMutation,
  useGetAdminUserUnresolvedIntegrationErrorsQuery,
  usePostAdminIntegrationErrorResolveMutation,
  useGetAdminCardTransactionsQuery,
  usePostIssuePhysicalCardMutation,
  useGetAdminFinancialHealthSurveyResultsQuery,
} = apiSlice;

export const getAdminUsers = (page, options?: {}) =>
  useGetAdminUsersQuery(page, options);

export const getAdminUsersCardholders = (page, options?: {}) =>
  useGetAdminUsersCardholdersQuery(page, options);

export const getAdminUsersApplicants = (page, options?: {}) =>
  useGetAdminUsersApplicantsQuery(page, options);

export const getAdminUsersEligible = (page, options?: {}) =>
  useGetAdminUsersEligibleQuery(page, options);

export const getAdminUser = (id, options?: {}) =>
  useGetAdminUserQuery(id, options);

export const getAdminUserPinwheelStatus = (id, options?: {}) =>
  useGetAdminUserPinwheelStatusQuery(id, options);

export const getAdminUserDirectDeposit = (id, options?: {}) =>
  useGetAdminUserDirectDepositQuery(id, options);

export const getAdminUserPlaidTransactions = (id, options?: {}) =>
  useGetAdminUserPlaidTransactionsQuery(id, options);

export const getAdminUserPlaidStatus = (id, options?: {}) =>
  useGetAdminUserPlaidStatusQuery(id, options);

export const getAdminUserUnresolvedIntegrationErrors = (id, options?: {}) =>
  useGetAdminUserUnresolvedIntegrationErrorsQuery(id, options);

export const getAdminWaitlist = (page, options?: {}) =>
  useGetAdminWaitlistQuery(page, options);

export const getAdminCardTransactions = (page, options?: {}) =>
  useGetAdminCardTransactionsQuery(page, options);

export const getAdminFinancialHealthSurveyResults = (page, options?: {}) =>
  useGetAdminFinancialHealthSurveyResultsQuery(page, options);

export default apiSlice;
