import React, { useEffect, useState } from "react";
import { AnimatePresence, Variants } from "framer-motion";
import { usePostFinancialHealthSurveyMutation } from "store/api/apiSlice";
import Loading from "components/ui/Loading";
import Step from "./Step";
import * as Styled from "./styled";
import { SurveyResults, SurveyStep } from "./types";
import { getInitialresults, getResults } from "./utils";

const VARIANTS = {
  enter: () => ({
    position: "absolute",
    zIndex: 1,
    x: "100%",
    opacity: 0,
  }),
  center: {
    position: "relative",
    zIndex: 1,
    x: 0,
    opacity: 1,
    transitionEnd: {
      x: 0,
    },
  },
  exit: () => ({
    position: "absolute",
    zIndex: 0,
    x: "-100%",
    opacity: 0,
  }),
} as Variants;

const FinancialHealthSurvey = () => {
  const [currentStep, setCurrentStep] = useState<SurveyStep | null>(null);
  const [results, setResults] = useState<SurveyResults | null>(null);
  const [postSurvey, postSurveyResults] =
    usePostFinancialHealthSurveyMutation();

  const getStarted = async () => {
    setCurrentStep(SurveyStep.Questionnaire);
  };

  const onSubmitQuestionnaire = (values) => {
    const questionnaireResults = getResults(
      values.questions.map((value) => parseInt(value, 10))
    );

    setResults(questionnaireResults);
    setCurrentStep(SurveyStep.Signup);
  };

  const onSubmitSignup = async (user) => {
    await postSurvey({ user, subscores: results.subscores });
  };

  useEffect(() => {
    const initialResults = getInitialresults();
    setResults(initialResults);
    setCurrentStep(initialResults ? SurveyStep.Results : SurveyStep.Intro);
  }, []);

  if (!currentStep)
    return (
      <Styled.Wrapper>
        <Loading />
      </Styled.Wrapper>
    );

  return (
    <Styled.Wrapper>
      <AnimatePresence initial={false}>
        <Styled.StepWrapper
          custom={currentStep}
          variants={VARIANTS}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 30,
            duration: 0.2,
          }}
          key={currentStep}
        >
          <Step
            currentStep={currentStep}
            results={results}
            getStarted={getStarted}
            onSubmitQuestionnaire={onSubmitQuestionnaire}
            onSubmitSignup={onSubmitSignup}
            postSurveyResults={postSurveyResults}
          />
        </Styled.StepWrapper>
      </AnimatePresence>
    </Styled.Wrapper>
  );
};

export default FinancialHealthSurvey;
